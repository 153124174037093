import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';

import type { AuthLoginFormProps, AuthLoginFormVO } from './type';

import { FormFieldText, Button, TextLink, Image } from '@/components/ui';
import { APP_META } from '@/constants/app';
import { ROUTE } from '@/constants/route';
import { UseUserDocument } from '@/graphql/generated.anonymous';

import {
  authLoginFormSchema,
  formInitialValues,
  formLabel,
  placeholder,
} from './const';
import { useLogin } from './hooks';

export const AuthLoginForm = ({ email, onAfterLogin }: AuthLoginFormProps) => {
  const [loginMutation, loginMutationState] = useLogin();

  const { control, handleSubmit } = useForm<AuthLoginFormVO>({
    mode: 'onBlur',
    resolver: zodResolver(authLoginFormSchema),
    defaultValues: {
      ...formInitialValues,
      email: email ?? formInitialValues.email,
    },
  });

  const submitHandler = (data: AuthLoginFormVO) => {
    loginMutation({
      variables: { user: data },
      awaitRefetchQueries: true,
      refetchQueries: [UseUserDocument],
      onCompleted: () => onAfterLogin(),
    });
  };

  return (
    <form>
      <div className={clsx('tw-flex tw-items-center tw-justify-center')}>
        <div className={clsx('tw-w-[160px]')}>
          <Image src="/koubo_logo.svg" alt="" unoptimized />
        </div>
      </div>
      <div className={clsx('tw-mt-8 tw-space-y-2.5')}>
        <FormFieldText
          id="email"
          wrapType="vertical"
          name="email"
          label={formLabel.email}
          isReadOnly={!!email}
          isRequired
          isBoldLabel
          placeholder={placeholder.email}
          control={control}
          onKeyDownEnter={handleSubmit(submitHandler)}
        />
        <FormFieldText
          id="password"
          wrapType="vertical"
          name="password"
          type="password"
          label={formLabel.password}
          isRequired
          isBoldLabel
          placeholder={placeholder.password}
          control={control}
          onKeyDownEnter={handleSubmit(submitHandler)}
        />
      </div>
      <p className={clsx('tw-mt-6 tw-text-center')}>
        パスワードをお忘れの方は
        <TextLink href={ROUTE.forgotPassword({ redirectUrl: APP_META.DOMAIN })}>
          こちら
        </TextLink>
      </p>
      <div className={clsx('tw-mt-6 tw-text-center')}>
        <Button
          theme="solid"
          isBlock={false}
          onClick={handleSubmit(submitHandler)}
          isLoading={loginMutationState.loading}
        >
          ログイン
        </Button>
      </div>
    </form>
  );
};
