import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { AuthLoginForm } from '@/components/features/auth';
import { LoadingOverlay, Paper } from '@/components/ui';
import { ROUTE } from '@/constants/route';
import { useUser } from '@/hooks/features/auth';

import { pathParameterParser } from './util';

export const LoginPage = () => {
  const router = useRouter();
  const { user, isLoading } = useUser();

  const email = useMemo(() => {
    const parseResult = pathParameterParser(router.query);
    return parseResult.success ? parseResult.data.email : undefined;
  }, [router.query]);

  const handleAfterLogin = () => {
    router.push(ROUTE.top());
  };

  if (isLoading) return <LoadingOverlay isLoading />;

  if (user) {
    router.replace(ROUTE.top());
    return <LoadingOverlay isLoading />;
  }

  return (
    <main
      className={clsx(
        'tw-min-h-screen tw-flex tw-items-center tw-justify-center tw-bg-gray-50'
      )}
    >
      <div className={clsx('tw-w-[448px]')}>
        <Paper>
          <AuthLoginForm email={email} onAfterLogin={handleAfterLogin} />
        </Paper>
      </div>
    </main>
  );
};
