import { APP_META } from '@/constants/app';
import { ROUTE } from '@/constants/route';
import { OWNER } from '@/constants/site';

export const generateVerificationEmail = () => {
  const placeholder = {
    verificationToken: '{{verificationToken}}',
  };
  const redirectUrl = `${APP_META.DOMAIN}${ROUTE.organization()}`;
  return {
    from: { name: 'Koubo' },
    subject: 'Koubo メールアドレス認証を完了してください',
    body: `※このメールは${OWNER.NAME}が運営するシステムから自動送信しています。

あなたが Koubo に新たにアカウントを作成しました。本メールアドレスがあなたのものであることを確認するため、以下のリンクをクリックして認証を完了してください。

${ROUTE.emailVerification()}?token=${
      placeholder.verificationToken
    }&redirectUrl=${encodeURIComponent(redirectUrl)}

このリンクはメール送信から1時間以内にのみ有効です。時間内にリンクをクリックして認証を完了してください。なお、あなたのアカウントは、上記のリンクをクリックして認証が完了するまで完全に有効化されません。

このメールに心当たりがない場合、あなたのメールアドレスが誤って使用された可能性があります。その場合は、このメールを無視してください。

―――――――――――――――――――――――――――――――――――
運営会社：株式会社${OWNER.NAME}
https://koubo.co.jp
`,
    placeholder,
  };
};
