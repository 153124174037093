import { AuthLoginFormLoginDocument } from '@/graphql/generated.anonymous';
import { useNotice } from '@/hooks/utils';
import { useHandleGraphQLError, useMutationWrapper } from '@/libs/apollo';

export const useLogin = () => {
  const { showToast } = useNotice();
  const { showErrorToast } = useHandleGraphQLError();

  return useMutationWrapper(AuthLoginFormLoginDocument, {
    refetchQueries: 'active',
    role: 'anonymous',
    onError: ({ graphQLErrors }) => {
      const unauthorizedError = graphQLErrors.find(
        (error) => error.extensions.code === 'unauthorized'
      );
      if (unauthorizedError) {
        showToast({
          status: 'error',
          title: unauthorizedError.message,
        });
        return;
      }
      showErrorToast(graphQLErrors);
    },
  });
};
