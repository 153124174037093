import type { AuthSignupFormVO } from './type';

import { authLoginFormSchema } from '@/components/features/auth/AuthLoginForm/const';
import { COMMON_LABEL } from '@/constants/label';

export const MIN_LENGTH = {
  PASSWORD: 6,
};

export const authSignupSchema = authLoginFormSchema;

export const formInitialValues: AuthSignupFormVO = {
  email: '',
  password: '',
};

export const formLabel: Record<keyof AuthSignupFormVO, string> = {
  email: COMMON_LABEL.email,
  password: 'パスワード',
};

export const placeholder: Record<keyof AuthSignupFormVO, string> = {
  email: COMMON_LABEL.email,
  password: 'パスワード',
};
