import { zodResolver } from '@hookform/resolvers/zod';
import { clsx } from 'clsx';
import { useForm } from 'react-hook-form';

import type { AuthSignupFormProps, AuthSignupFormVO } from './type';

import { generateVerificationEmail } from '@/components/features/auth/AuthSignupForm/util';
import { FormFieldText, Button, TextLink, Image } from '@/components/ui';
import { ROUTE } from '@/constants/route';
import {
  AuthSignupFormSignupDocument,
  UseUserDocument,
} from '@/graphql/generated.anonymous';
import { useMutationWrapper } from '@/libs/apollo';

import {
  authSignupSchema,
  formInitialValues,
  formLabel,
  placeholder,
} from './const';

export const AuthSignupForm = ({
  email,
  onAfterSignup,
}: AuthSignupFormProps) => {
  const [signupMutation, signupMutationState] = useMutationWrapper(
    AuthSignupFormSignupDocument,
    {
      role: 'anonymous',
      refetchQueries: 'active',
    }
  );

  const { control, handleSubmit } = useForm<AuthSignupFormVO>({
    mode: 'onBlur',
    resolver: zodResolver(authSignupSchema),
    defaultValues: {
      ...formInitialValues,
      email: email ?? formInitialValues.email,
    },
  });

  const submitHandler = (data: AuthSignupFormVO) => {
    signupMutation({
      variables: {
        user: data,
        verificationEmail: generateVerificationEmail(),
      },
      awaitRefetchQueries: true,
      refetchQueries: [UseUserDocument],
      onCompleted: () => onAfterSignup(),
    });
  };

  return (
    <form>
      <div className={clsx('tw-flex tw-items-center tw-justify-center')}>
        <div className={clsx('tw-w-[160px]')}>
          <Image src="/koubo_logo.svg" alt="" unoptimized />
        </div>
      </div>
      <div className={clsx('tw-mt-8 tw-space-y-2.5')}>
        <FormFieldText
          id="email"
          wrapType="vertical"
          name="email"
          label={formLabel.email}
          isReadOnly={!!email}
          isRequired
          isBoldLabel
          autoComplete="off"
          placeholder={placeholder.email}
          control={control}
          onKeyDownEnter={handleSubmit(submitHandler)}
        />
        <FormFieldText
          id="password"
          wrapType="vertical"
          name="password"
          type="password"
          label={formLabel.password}
          isRequired
          isBoldLabel
          autoComplete="off"
          placeholder={placeholder.password}
          helperText="半角英数字6文字以上"
          control={control}
          onKeyDownEnter={handleSubmit(submitHandler)}
        />
      </div>
      <p className={clsx('tw-mt-6')}>
        <TextLink href={ROUTE.sitePolicy()}>利用規約</TextLink>と
        <TextLink href={ROUTE.privacy()}>
          個人情報のお取り扱いに関する同意事項
        </TextLink>
        に同意した方のみ送信してください
      </p>
      <div className={clsx('tw-mt-6 tw-text-center')}>
        <Button
          theme="solid"
          isBlock={false}
          onClick={handleSubmit(submitHandler)}
          isLoading={signupMutationState.loading}
        >
          新規ユーザー登録
        </Button>
      </div>
      <p className={clsx('tw-mt-6 tw-text-center')}>
        アカウントをお持ちの方は
        <TextLink href={ROUTE.login()}>こちら</TextLink>
      </p>
    </form>
  );
};
