import type { AuthLoginFormVO } from './type';

import { VALIDATION_ERROR_MESSAGE } from '@/constants/error';
import { PASSWORD_REGEX } from '@/constants/features/auth';
import { COMMON_LABEL } from '@/constants/label';
import { z } from '@/libs/zod';

export const MIN_LENGTH = {
  PASSWORD: 6,
};

export const authLoginFormSchema = z.object({
  email: z.customEmail().min(1, VALIDATION_ERROR_MESSAGE.REQUIRED),
  password: z
    .requiredString()
    .min(
      MIN_LENGTH.PASSWORD,
      VALIDATION_ERROR_MESSAGE.MIN_STRING_LENGTH(MIN_LENGTH.PASSWORD)
    )
    .regex(PASSWORD_REGEX, VALIDATION_ERROR_MESSAGE.STRING_AND_NUMBER),
});

export const formInitialValues: AuthLoginFormVO = {
  email: '',
  password: '',
};

export const formLabel: Record<keyof AuthLoginFormVO, string> = {
  email: COMMON_LABEL.email,
  password: 'パスワード',
};

export const placeholder: Record<keyof AuthLoginFormVO, string> = {
  email: COMMON_LABEL.email,
  password: 'パスワード',
};
